import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./styles.css";
import logo from "./microdome.png";

function Main() {
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          custom_id: customIdRef.current, //optional customer name
          invoice_id: invoiceIdRef.current, //optional invoice id
          description: customIdRef.current, //optional customer name, placed into description
          amount: {
            currency_code: "USD",
            value: amountRef.current,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      //alert("Success! Your payment is complete.");
      setMessage("Payment successful! Your payment is complete.");
      window.location.replace("https://pay.microdome.net/success.html");
    });
  };

  function Message({ content }) {
    return <p className="message">{content}</p>;
  }

  const onError = (data, actions) => {
    //alert(data);
    setMessage("Error! Your payment was not successful.");
  };

  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");
  const [custom_id, setNameOrCompany] = useState("");
  const [invoice_id, setInvoiceNumber] = useState("");

  const amountRef = useRef(amount);
  const customIdRef = useRef(custom_id);
  const invoiceIdRef = useRef(invoice_id);

  // Update refs whenever state changes
  useEffect(() => {
    amountRef.current = amount;
    customIdRef.current = custom_id;
    invoiceIdRef.current = invoice_id;
  }, [amount, custom_id, invoice_id]);

  return (
    <>
      <div>
        <img src={logo} className="logo" alt="Microdome logo" />
      </div>

      <hr />

      <div className="container">
        <p>
          Enter the <strong>First and Last Name, or Company Name</strong> on
          your account:
        </p>
        <input
          placeholder="Name or Company Name"
          value={custom_id}
          onChange={(e) => setNameOrCompany(e.target.value)}
        />
        <p>
          Enter the <strong>Invoice, Service Order, or Statement Number</strong>
          . If you do not have a number, please give a brief description to help
          us locate your account:
        </p>
        <input
          placeholder="Invoice Number"
          value={invoice_id}
          onChange={(e) => setInvoiceNumber(e.target.value)}
        />
        <p>
          Enter the <strong>Dollar Amount</strong> you are paying today:
        </p>
        $ {}
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <hr />
        <p>
          You may pay with <strong>PayPal or Debit/Credit Card</strong>.
        </p>
      </div>

      <PayPalScriptProvider
        options={{
          "client-id":
            "Ab8wTTmQfrMr1QQTt2nxNulYSbv3UirjNiirixmIPBfBpmJusGVgqRMuAmhMLM4ISEpWJrUb18SAFvQ7",
          "disable-funding": "paylater",
        }}
      >
        <div>
          <PayPalButtons
            style={{ layout: "vertical", color: "blue" }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </div>
      </PayPalScriptProvider>
      <Message content={message} />
    </>
  );
}

function App() {
  return <Main />;
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
